import React, { useState, useEffect, useRef } from 'react';
import Layer from '@sentry/static-shared/components/layer-next';
import FlexGrid, { FlexGridCell } from '@sentry/static-shared/components/FlexGrid';
import { blurple, colorTextLight, dkPurple, mdPink, richBlack, white } from '@sentry/static-shared/utils/css/colors';
import Button from '@sentry/static-shared/components/Button';
import styled from '@emotion/styled';
import { mqMin } from '@sentry/static-shared/utils/css';
import Search from '@sentry/static-shared/components/search';
import SearchIcon from '@sentry/static-shared/icons/magnifying-glass.svg';

const HeroLayer = ({ title }) => {

  const [showSearch, setShowSearch] = useState(false);
  const prevShowSearch = useRef(false);

  const onSearchClick = () => setShowSearch(!showSearch)

  const searchBar = useRef();

  const animateSearchBar = () => {
    const searchBarNode = searchBar.current;
    if (showSearch !== prevShowSearch.current) {
      if (showSearch) {
        searchBarNode.classList.add('visible');
      } else {
        searchBarNode.classList.remove('visible');
      }
      prevShowSearch.current = showSearch;
    }
  }

  useEffect(() => {
    const slightDelay = setTimeout(() => {
      animateSearchBar()
    }, 100);
    return () => clearTimeout(slightDelay);
  }, [showSearch])

  const SearchComponent = () => (
    <StyledSearchContainer ref={searchBar}>
      <Search
        placeholder="Search the blog (and more)"
        config={['blog', 'zendesk_sentry_articles', 'docs']}
      />
      <button id="clear-search" onClick={onSearchClick}>x</button>
    </StyledSearchContainer>
  );

  const ArchiveAndFeedLinks = () => (
    <>
      <StyledButton
        redesign
        to="/archive"
        target="_blank"
      >
        archive
      </StyledButton>
      <StyledButton
        redesign
        to="/feed.xml"
        target="_blank"
      >
        rss feed
      </StyledButton>
    </>
  );

  return (
    <StyledLayer
      backgroundColor={richBlack}
      lightType
    >
      <StyledFlexGrid>
        <TitleWrapper md={7}>
          <h1>{title}</h1>
        </TitleWrapper>
        <DesktopSearchWrapper md={5}>
          <DesktopButtonsContainer visible={!showSearch}>
            <StyledSearchIcon onClick={onSearchClick}/>
            <ArchiveAndFeedLinks/>
          </DesktopButtonsContainer>
          <SearchComponent/>
        </DesktopSearchWrapper>
      </StyledFlexGrid>
    </StyledLayer>
  );
};

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: .5rem;
`;

const StyledLayer = styled(Layer)`
  z-index: 1;

  ${mqMin('md')} {
    div.container {
      padding-bottom: 2rem;
    }
  }
`;

const StyledFlexGrid = styled(FlexGrid)`
  ${mqMin('md')} {
    justify-content: space-between;
  }
`;

const DesktopButtonsContainer = styled.div`
  display: ${props => props?.visible ? 'block' : 'none'};
  z-index: 0;
  justify-content: flex-start;
  ${mqMin('lg')}{
    justify-content: flex-end;
    align-items: center;
  }
`;

const TitleWrapper = styled(FlexGridCell)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: auto;
  ${mqMin('md')} {
    justify-content: space-between;
  }
  h1 {
    margin-bottom: 0;
  }
  margin-bottom: 1.5rem;
`;

const DesktopSearchWrapper = styled(FlexGridCell)`
  position: relative;
  height: 2.5rem;
  ${mqMin('lg')}{
    flex-direction: row;
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledSearchContainer = styled.div`
  display: block;
  position: relative;
  z-index: 10;
  width: 0;
  overflow-x: hidden !important;
  transition: all 0.5s linear;
  transform-origin: right center;
  &.visible{
    width: 22rem;
    overflow: visible !important;
    > div {
      input {
        width: 22rem;
      }
    }
  }
  > div {
    margin-bottom: 1rem;
    input {
      transition: all 0.5s linear;
      width: 0;
    }
    ${mqMin('md')}{
      margin-bottom: 0;
      .sgs-search-results {
        width: 43.4375rem;
        right: -3px;
      }
    }
  }
  input {
    padding-right: 0.5rem;
    line-height: 1.5;
    display: inline;
    margin: 0 auto;
    border-radius: 0;
    border: 1px solid ${colorTextLight};
    background: ${white};
    color: ${dkPurple};
    font-size: 1rem;
    
    ${mqMin('md')} {
      margin-top: .5rem;
      max-width: 21.875rem;
    }
    ${mqMin('lg')} {
      width: 21.875rem;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
  button#clear-search {
    position: absolute;
    right: 5px;
    bottom: .55rem;
    ${mqMin('lg')}{
      bottom: 0.375rem;
    }
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: block;
    color: ${blurple};
  }
`;

const StyledButton = styled(Button)`
  color: ${mdPink};
`;

export default HeroLayer;