import React from 'react';
import styled from '@emotion/styled';
import PostDate from '@sentry/static-shared/components/PostDate';
import { mdPink, mdYellow, richBlack, white } from '@sentry/static-shared/utils/css/colors';
import Link from '@sentry/static-shared/components/link';
import { mqMin } from '@sentry/static-shared/utils/css';
import { defaultDrop } from '@sentry/static-shared/utils/css/shadows';
import { borderRadius } from '@sentry/static-shared/utils/css/constants';
import AuthorByline from '../AuthorByline';

const BlogPostCard = ({ title, excerpt, date, cta, metaImage, imageHeight, author: authors, defaultAvatars }) => {
  return (
    <CardContainer>
      <StyledCardBodyLink to={cta?.link} aria-label={title}/>
      <MetaImageWrapper>
        <Thumbnail image={metaImage} imageHeight={imageHeight}/>
      </MetaImageWrapper>
      <ContentWrapper>
        <h3>{title}</h3>
        <p>{excerpt}</p>
      </ContentWrapper>
      <AuthorByline
        fallbackAvatars={defaultAvatars}
        contentfulAuthors={authors}
        date={date}
        dark
      />
      <CtaWrapper>
        <Link to={cta?.link} aria-label={title}>{cta?.text}</Link>
      </CtaWrapper>
    </CardContainer>
  );
};

const AuthorNames = styled.p`
  margin-bottom: 0;
  line-height: 1.5;
`;

const StyledPostDate = styled(PostDate)`
  display: inline-block;
`;

const StyledCardBodyLink = styled(Link)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: box-shadow 0.5s;
  will-change: box-shadow;
  outline: 0;
  border-radius: ${borderRadius};
  margin: -.5rem;
  &:hover,
  &:focus {
    text-decoration: none;
    box-shadow: ${defaultDrop}, -0.1875rem -0.1875rem 0 0.1875rem ${mdYellow},
    0 0 0 0.375rem ${mdPink};
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const MetaImageWrapper = styled.div`
  margin-bottom: 1rem;
`;

const ContentWrapper = styled.div`
  h3 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  p {
    line-height: 1.5;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CtaWrapper = styled.div`
  a {
    color: ${mdPink};
    font-size: 14px;
    text-transform: uppercase;
  }
`;

const angle = 3.49;

const Thumbnail = styled.div`
  background-image: url(${({ image }) => image});
  background-size: cover;
  width: 100%;
  padding-bottom: 50%;
  flex-grow: 1;
  background-position: 50% 50%;
`;

export default BlogPostCard;